<template>
  <div class="booking_modal uk-padding-remove">
    <button class="vm-modal__close" @click="$emit('close')">
      <span uk-icon="icon: close; ratio: 1"></span>
    </button>
    <div class="">
      <div
        class="uk-grid-collapse uk-child-width-1-1@s uk-child-width-1-2@m uk-flex-middle"
        uk-grid="uk-grid"
      >
        <div
          class="uk-background-cover uk-visible@s"
          style="background-image: url('booking_asset.jpg');"
          uk-height-viewport="uk-height-viewport"
        ></div>
        <div>
          <iframe
            class="uk-width-expand uk-height-max-large uk-modal-container"
            src="https://termin.visionsbox.de/"
            width="100%"
            height="100%"
            scrolling="yes"
            style="border:0"
            uk-height-viewport="uk-height-viewport"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Booking",
  props: {},
  data: function() {
    return {};
  },
  computed: {},

  methods: {}
};
</script>

<style scoped lang="scss">
.booking_modal {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.vm--modal {
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;

  & > div {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
