<template lang="html">
  <SlideOut
    append-to=".videoapp"
    dock="right"
    :visible.sync="visible"
    size="100%"
    mask-color="rgba(0,0,0,.25)"
    :title="article.title"
    custom-class="article-slideout"
    @close="onClose"
  >
    <vue-custom-scrollbar>
      <div>
        <div class="uk-background-muted uk-padding body">
          <div
            class="category uk-margin uk-margin-remove-top uk-width-small"
            v-if="article.category"
          >
            <img
              :src="_vaData.apiBase + article.category.logo"
              :alt="article.category.title"
            />
          </div>
          <h1
            v-if="article.title"
            v-html="article.title"
            class="body__title"
          ></h1>
          <h3
            v-if="article.subtitle"
            v-html="article.subtitle"
            class="body__subtitle"
          ></h3>
          <div v-if="article.body">
            <vue-markdown>
              {{ article.body }}
            </vue-markdown>
          </div>
        </div>
      </div>

      <Article
        class="uk-background-default uk-box-shadow-medium uk-margin articles"
        v-for="(slide, index) in article.slides"
        :key="index"
        :slide="slide"
      ></Article>
      <div class="uk-background-default uk-padding uk-margin-medium-bottom">
        <div
          class="uk-child-width-expand uk-margin-remove-top uk-margin-remove-left "
          uk-grid
        >
          <!-- <CallToContact :referer="article.title"></CallToContact> -->
          <div
            v-if="article.product_link"
            class="finder_link__button uk-button"
          >
            <a
              class="finder_link uk-button uk-width-1-1"
              :href="article.product_link"
              target="_blank"
              title="Produktlink"
            >
              <span class="margin-small-right"></span
              >{{ strings.product_link }}</a
            >
          </div>
        </div>
      </div>
    </vue-custom-scrollbar>
  </SlideOut>
</template>

<script>
import SlideOut from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/lib/slideout.css";
import vueCustomScrollbar from "vue-custom-scrollbar";
import { bus } from "../../main";
import Article from "./Article.vue";
// import CallToContact from "./CallToContact.vue";
export default {
  data: function() {
    return {
      visible: false,
      strings: this._vaData.strings,
      article: Object
    };
  },
  props: {
    product_link: String
  },
  components: {
    SlideOut,
    Article,
    vueCustomScrollbar
    // CallToContact,
  },
  methods: {
    onClose() {
      const params = new URLSearchParams(location.search);
      params.delete("content");
      window.history.replaceState({}, "", `?${params}`);
    }
  },
  mounted() {
    bus.$on("show_slideout", data => {
      this.visible = true;
      this.title = data.title;
      this.article = data;
    });
    bus.$on("hide_slideout", () => {
      this.visible = true;
    });
  }
};
</script>

<style lang="scss" scoped="">
.body__title {
  margin-bottom: 0px;
}
.body__subtitle {
  color: #6f6f6e;
  display: block;
  font-size: 1.5em;
  line-height: 1.4;
  margin-top: 0;
}
ul {
  margin-bottom: 10px;
}
.articles {
  padding: 30px;
}
.vue-slideout-header {
  padding: 5px 30px;
}
</style>
