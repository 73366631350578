<template lang="html">
  <header class="header">
    <a
      class="logo"
      :title="strings.app_title"
      v-if="settings.logo"
      @click="goHome"
    >
      <img :src="_vaData.apiBase + settings.logo" :alt="strings.app_title" />
    </a>
    <a
      class="logo_projekte"
      :title="strings.app_title"
      v-if="settings.logo_projekte"
      @click="goHome"
    >
      <img
        :src="_vaData.apiBase + settings.logo_projekte.url"
        :alt="strings.app_title"
      />
    </a>
    <Title class="header_title" />

    <button class="menu-btn" uk-toggle="target: #offcanvas">
      <div></div>
      <div></div>
      <div></div>
    </button>
  </header>
</template>

<script>
import Title from "./Title.vue";
import { bus } from "../main";

export default {
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings
    };
  },
  methods: {
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  components: {
    Title
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
  font-size: 14px;
  padding: 5px 0px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  gap: 2vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: $breakpoint-large) {
    padding: 15px;
    // justify-content: space-between;
    position: static;
  }

  a,
  button {
    pointer-events: all;
  }
}

.logo {
  pointer-events: all;
  min-width: 790px;
  max-width: 790px;

  img {
    width: 100%;
    display: block;
  }
}
.logo_projekte {
  pointer-events: all;
  min-width: 180px;
  max-width: 180px;

  img {
    width: 100%;
    display: block;
  }
}

.header_title {
  float: right;
  position: absolute;
  right: 120px;
  @media (max-width: $breakpoint-large) {
    display: none;
  }
}

.menu-btn {
  height: 36px;
  width: 36px;
  margin-bottom: 26px;
  flex-shrink: 0;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
  position: absolute;
  right: 30px;
  cursor: pointer;
  transition: background-color 0.2s;
  & > div {
    height: 2px;
    width: 90%;
    background: #000;
    transition: background-color 0.2s;
  }

  &:hover {
    & > div {
      background: $global-emphasis-color;
    }
  }

  .uk-offcanvas-container & {
    & > div {
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      // z-index: ;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
</style>
