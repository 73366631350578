<template>
  <div class="uk-position-relative slide">
    <div v-if="slide.title || slide.subtitle" class="header">
      <h3 class="slide__title" v-if="slide.title">{{ slide.title }}</h3>
      <span class="slide__subtitle" v-if="slide.subtitle">{{
        slide.subtitle
      }}</span>
    </div>
    <div class="slide__media">
      <div v-if="!slide.src" class="media">
        <!-- Fallback -->
        <img src="fallback.jpg" alt="Asset not found" />
      </div>

      <template v-else>
        <div v-if="slide.type === 'video'" class="media">
          <video
            class="uk-width-1-1"
            controls
            :poster="_vaData.apiBase + slide.poster"
            :src="_vaData.apiBase + slide.src"
          ></video>
        </div>

        <div v-if="slide.type === 'image'" class="media">
          <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
        </div>

        <div v-if="slide.type === 'iframe'" class="media">
          <div class=" media_iframe">
            <iframe
              :src="
                slide.src +
                  (slide.src.includes('youtube') ? '?enablejsapi=1' : '')
              "
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div v-if="slide.caption" class="caption">
          <span>{{ slide.caption }}</span>
        </div>
      </template>
    </div>
    <div v-if="slide.body" class="body">
      <vue-markdown>{{ slide.body }}</vue-markdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "Article",
  components: {},
  mounted() {
    // const isSmall = window.innerWidth < 960;
  },
  data() {
    return {
      strings: this._vaData.strings
    };
  },
  props: {
    slide: Object
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  padding-right: 70px;
  @media (min-width: 960px) {
    height: 60px;
  }
}
.slide__title {
  margin-bottom: 0;
  color: $global-tertiary-color;
}

.slide__subtitle {
  text-transform: uppercase;
}

.slide__media {
  position: relative;
}
.body {
  padding-top: 20px;
}

.media {
  position: relative;
  text-align: center;
  // aspect-ratio: 16 / 9;
  display: block;
  width: 100%;
  overflow: hidden;
  &:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0.5em 1em;
  font-size: 11px;
  line-height: 1.5;
  // color: #fff;
  span {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
  }
}

.media_iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  iframe {
    height: 100%;
  }
}
</style>
